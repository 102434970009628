const ErrorPage = ({}) => {
    return (
      <>
        {/*
            This example requires updating your template:
    
            ```
            <html class="h-full">
            <body class="h-full">
            ```
          */}
        <div className="grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
          <header className="w-full px-6 pt-6 mx-auto max-w-7xl sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
            <a href="#">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                className="w-auto h-10 sm:h-12"
              />
            </a>
          </header>
          <main className="w-full px-6 py-24 mx-auto max-w-7xl sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
            <div className="max-w-lg">
              <p className="text-base font-semibold leading-8 text-indigo-600">
                404
              </p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-10">
                <a
                  href="#"
                  className="text-sm font-semibold leading-7 text-indigo-600">
                  <span aria-hidden="true">&larr;</span> Back to home
                </a>
              </div>
            </div>
          </main>
          <footer className="self-end lg:col-span-2 lg:col-start-1 lg:row-start-3">
            <div className="py-10 border-t border-gray-100 bg-gray-50">
              <nav className="flex items-center w-full px-6 mx-auto text-sm leading-7 text-gray-600 max-w-7xl gap-x-4 lg:px-8">
                <a href="#">Contact support</a>
                <svg
                  viewBox="0 0 2 2"
                  aria-hidden="true"
                  className="h-0.5 w-0.5 fill-gray-300">
                  <circle
                    r={1}
                    cx={1}
                    cy={1}
                  />
                </svg>
                <a href="#">Status</a>
              </nav>
            </div>
          </footer>
          <div className="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block">
            <img
              alt=""
              src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80"
              className="absolute inset-0 object-cover w-full h-full"
            />
          </div>
        </div>
      </>
    );
  };
  
  export default ErrorPage;
  